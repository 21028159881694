<template>
    <Modal title="编辑权限" v-model="showModel" ref="editModal" class-name="vertical-center-modal" @on-ok="editSubmit"
           @on-cancel="showEditModal=false" :loading="modalEditLoading">
        <Form ref="editForm" label-colon :label-width="90">
            <FormItem label="角色名称" prop="roleName">
                <div>{{ editForm.roleName }}</div>
            </FormItem>
            <FormItem label="当前权限" prop="mail">
                <Tree ref="tree" :data="treeData" show-checkbox multiple></Tree>
            </FormItem>
        </Form>
    </Modal>
</template>

<script>
import {reqEditRolePermission, queryPermissions, reqGetRoleById} from "../../../api/system-api";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        roleId: Number
    },
    data() {
        return {
            editForm: {
                roleId: 0,
                roleName: '',
                description: '',
                delId: '',
            },
            showModel: false,
            treeData: [],
            modalEditLoading: false,
        }
    },
    watch: {
        value(val) {
            this.showModel = val;
        },
        showModel(val) {
            //当重新显示增加数据的时候重置整个form表单
            this.showModel = val;
            if (val) {
                this.treeData = [];
                this.getAllMenuList(this.roleId);
                this.getRoleById(this.roleId)
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    methods: {
        getRoleById(roleId) {
            reqGetRoleById({roleId: roleId}).then(res => {
                if (res.data.code === 1) {
                    this.editForm = res.data.data;
                } else {
                    this.$Message.error('查询失败');
                }
            }).catch(() => {
                this.$Message.error('查询失败');
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        },
        //编辑权限
        editSubmit: function () {
            let that = this;
            that.modalEditLoading = true;
            delete that.editForm['createTime'];
            let checkedData = this.$refs.tree.getCheckedNodes();
            let idsArrays = [];
            checkedData.forEach(function (obj, i) {
                idsArrays.push(obj.permissionId)
            });
            that.editForm.roleId = that.roleId;
            let para = that.editForm;
            para.permissionIds = idsArrays.join(',');
            reqEditRolePermission(para).then((res) => {
                that.modalEditLoading = false;
                if (res.data.code === 1) {
                    that.closeModal(false);
                    that.$Message.success('修改成功');
                } else {
                    that.$Message.error('修改失败');
                }
            }).catch(() => {
                that.modalEditLoading = false;
                that.$Message.error('修改失败');
            })
        },

        getAllMenuList(roleId) {
            let that = this;
            that.modalEditLoading = true;
            queryPermissions({roleId: roleId}).then((res) => {
                that.modalEditLoading = false;
                if (res.data.code === 1) {
                    that.treeData = res.data.data;
                } else {
                    that.$Message.error('查询失败');
                }
            }).catch(() => {
                that.modalEditLoading = false;
                that.$Message.error('查询失败');
            })
        },
    },
    mounted() {
    }
}
</script>